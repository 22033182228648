export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore()

  if (
    authStore.check &&
    !(authStore.user?.moderator || authStore.user?.admin)
  ) {
    return navigateTo({ name: "pricing" })
  }
})
